import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ::selection {
    background-color: #FFE6D9;
    color: #1E1E1E;
  }

  ::-moz-selection {
    background-color: #FFE6D9;
    color: #1E1E1E;
  }
`;

export default GlobalStyle;
