import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import JobListings from './components/JobListings';
import JobDetails from './components/JobDetails';
import './App.css';
import GlobalStyle from './globalStyles';
import NotFound from './components/NotFound';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';

// Initialize GA4 with your measurement ID
ReactGA.initialize("G-PS7JDS99DB"); // Replace with your actual GA4 measurement ID

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <>
      <GlobalStyle />
      <div className="App dark">
        <Helmet>
          <title>PD JOBS - Product Design Job Board</title>
        </Helmet>
        <Routes>
          <Route path="/" element={<JobListings />} />
          <Route path="/jobs/:id" element={<JobDetails />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
