import React from 'react';
import { useNavigate } from 'react-router-dom';
import './JobListings.css';
import './JobTableContainer.css';

function JobTableContainer({ jobs, onJobClick, sortConfig, onRequestSort }) {
  const navigate = useNavigate();

  console.log('Rendering JobTableContainer, jobs:', jobs.length);

  const displaySalaryRange = (salaryRange) => {
    if (salaryRange && salaryRange.trim() !== '') {
      return '$' + salaryRange.replace(/^\$/, '');
    } else {
      return '—';
    }
  };

  const getTitleColumnStyle = (column) => {
    return column === 'title' ? { minWidth: '460px' } : {};
  };

  return (
    <div className="job-table-container">
      <table className="job-table">
        <thead>
          <tr>
            <th onClick={() => onRequestSort('title')}>
              Title {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => onRequestSort('company')}>
              Company {sortConfig.key === 'company' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => onRequestSort('location')}>
              Location {sortConfig.key === 'location' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => onRequestSort('SALARYRANGE')}>
              Salary Range {sortConfig.key === 'SALARYRANGE' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => onRequestSort('employmentType')}>
              Employment Type {sortConfig.key === 'employmentType' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => onRequestSort('datePosted')}>
              Date Posted {sortConfig.key === 'datePosted' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr
              key={job.id}
              onClick={() => onJobClick(job)}
            >
              <td data-label="Title">{job.title}</td>
              <td data-label="Company">{job.company}</td>
              <td data-label="Location">{job.location}</td>
              <td data-label="Salary Range">{displaySalaryRange(job.SALARYRANGE)}</td>
              <td data-label="Employment Type">{job.employmentType}</td>
              <td data-label="Date Posted">{job.datePosted}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {jobs.length === 0 && (
        <p className="no-jobs-message">No jobs found. Try adjusting your filters.</p>
      )}
    </div>
  );
}

export default React.memo(JobTableContainer);
